<template>
  <loading
    :active="isLoading"
    :is-full-page="fullPage"
    :z-index="1100"
  ></loading>
  <div class="mx-auto my-auto text-center" v-if="!loaded">
    <div class="spinner-border text-gray-600" role="status"></div>
    <span class="text-gray-600 ps-3 fs-2">Loading</span>
  </div>
  <div
    v-else-if="
      omologazioni_list.length === 0 &&
      loaded &&
      (status == 200 || status == 204)
    "
    class="mx-auto my-auto text-center card shadow pt-5 mt-5 mb-5 pb-5"
  >
    <!-- <i class="bi bi-exclamation-triangle fs-2 text-black pb-4"></i> -->
    <span class="text-gray-700 ps-3 fs-2"> Attenzione! Nessun risultato. </span>
  </div>
  <div
    v-else-if="status != 200 && status != 204"
    class="mx-auto my-auto text-center card shadow pt-5 mt-5 mb-5 pb-5"
  >
    <!-- <i class="bi bi-exclamation-triangle fs-2 text-black pb-4"></i> -->
    <span class="text-gray-700 ps-3 fs-2">
      Attenzione! Si è verificato un errore. Riprovare più tardi.
    </span>
    <span
      type="button"
      data-bs-toggle=""
      data-bs-target=""
      aria-expanded="false"
      aria-controls=""
      class="text-gray-600 pt-4"
    >
      <router-link v-if="isEnabled('fnLinkBachecaCompleta')" to="/bacheca">
        <i class="bi bi-house fs-2 text-gray-800"> </i
      ></router-link>
      <router-link v-else to="/bacheca-public">
        <i class="bi bi-house fs-2 text-gray-800"> </i></router-link
    ></span>
  </div>
  <div v-else>
    <Datatable
      :table-header="tableHeader"
      :table-data="omologazioni_list"
      :total="record"
      :current-page="currentPage"
      :rows-per-page="fetchRows"
      :sortLabel="sortColumn"
      :order="sortOrder"
      @sort="setSortOrderColumn"
      @items-per-page-change="setFetchRowsOmologList"
      @current-change="setCurrentPageOmologList"
    >
      <template v-slot:cell-richiedente="{ row: data }"
        ><div class="tab-long-xl">{{ data.richiedente }}</div></template
      >
      <template v-slot:cell-anno="{ row: data }">{{ data.anno }}</template>
      <template v-slot:cell-disciplina="{ row: data }">{{
        data.disciplina
      }}</template>
      <template v-slot:cell-data_inizio="{ row: data }">
        <div v-if="data.data_inizio">{{ getFormatDate(data.data_inizio) }}</div>
      </template>
      <template v-slot:cell-data_fine="{ row: data }"
        ><div v-if="data.data_fine">
          {{ getFormatDate(data.data_fine) }}
        </div></template
      >
      <template v-slot:cell-stato="{ row: data }">
        <div>
          <h1
            class="mb-0 badge"
            :class="
              data.stato === 'Approvata'
                ? 'badge-light-success'
                : data.stato === 'In attesa di verifica'
                ? 'badge-light-warning'
                : data.stato === 'Rifiutata'
                ? 'badge-light-danger'
                : data.stato === 'Richiesta approvazione'
                ? 'badge-light-primary'
                : data.stato === 'Presa in carico'
                ? 'badge-light'
                : data.stato === 'Bozza'
                ? 'badge-light'
                : ''
            "
          >
            {{ data.stato }}
          </h1>
        </div>
      </template>
      <template v-slot:cell-options="{ row: data }">
        <div class="btn-group">
          <button
            class="btn btn-sm dropdown"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            @click="
              id_Omologazione = data.id;
              stato_omologazione = data.stato;
              livello = data.id_livello_omologazione;
            "
          >
            <i class="bi bi-three-dots-vertical text-dark fs-4"></i>
          </button>
          <ul class="dropdown-menu">
            <li
              role="presentation"
              class="navi-item"
              v-if="
                data.stato == 'Approvata' && data.puo_annullareappr_modificaliv
              "
            >
              <div class="b-dropdown-text text-start py-2 px-4">
                <div
                  type="button"
                  class="navi-link"
                  data-bs-toggle="modal"
                  data-bs-target="#modal_change_livello"
                >
                  <i class="bi bi-123 text-dark fs-5"></i>
                  <span class="text-dark ps-1 fs-7 pe-2">
                    Modifica livello
                  </span>
                </div>
              </div>
            </li>
            <router-link
              v-if="data.stato != 'Approvata'"
              :to="{
                name: 'dettaglio-omologazione-soc',
                params: {
                  id: data.id_societa,
                  id_impianto: data.id_impianto,
                  id_omo: data.id,
                },
              }"
            >
              <li role="presentation" class="navi-item">
                <div class="b-dropdown-text text-start py-2 px-4">
                  <div class="navi-link">
                    <i class="bi bi-pen text-dark fs-5"></i>
                    <span class="text-dark ps-1 fs-7"> Modifica </span>
                  </div>
                </div>
              </li></router-link
            >
            <router-link
              v-if="data.stato == 'Approvata'"
              :to="{
                name: 'dettaglio-omologazione-soc',
                params: {
                  id: data.id_societa,
                  id_impianto: data.id_impianto,
                  id_omo: data.id,
                },
              }"
            >
              <li role="presentation" class="navi-item">
                <div class="b-dropdown-text text-start py-2 px-4">
                  <div class="navi-link">
                    <i class="bi bi-eye text-dark fs-5"></i>
                    <span class="text-dark ps-1 fs-7"> Visualizza </span>
                  </div>
                </div>
              </li></router-link
            >
            <a
              href="#"
              data-bs-toggle="modal"
              data-bs-target="#modal_doc_impianti"
              @click="refresh = !refresh"
            >
              <li role="presentation" class="navi-item">
                <div class="b-dropdown-text text-start py-2 px-4">
                  <div class="navi-link">
                    <i class="bi bi-files text-dark fs-5"></i>
                    <span class="text-dark ps-1 fs-7"> Documenti </span>
                  </div>
                </div>
              </li></a
            >
          </ul>
        </div>
      </template>
    </Datatable>
    <ModalDocImpianti
      :id_Omologazione="id_Omologazione"
      :stato="stato_omologazione"
      :refresh="refresh"
    />
    <ModalChangeLivello
      :id_Omologazione="id_Omologazione"
      :livello="livello"
      :denominazioneSelected="denominazione_impianto"
      @getList="getOmologList()"
    />
  </div>
</template>

<script>
import { defineComponent, computed, getCurrentInstance, watch, ref } from "vue";
import { useStore } from "vuex";
import Datatable from "../../../kt-datatable/KTDatatable.vue";

import {
  inCaricoSiNoOmologazione,
  attesaVerificaOmologazione,
  approvaOmologazione,
  rifiutaOmologazione,
} from "@/requests/impiantiOmologazione";

import { alertSuccess, alertFailed } from "@/composables/swAlert";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import isEnabled from "@/composables/isEnabled.js";

import Loading from "vue3-loading-overlay";
import "vue3-loading-overlay/dist/vue3-loading-overlay.css";

import ModalDocImpianti from "@/components/components-fit/impianti/ModalDocImpianti";
import ModalChangeLivello from "@/components/components-fit/omologazioni/ModalChangeLivello.vue";

export default defineComponent({
  name: "table-omologazioni-soc",
  components: { Datatable, Loading, ModalDocImpianti, ModalChangeLivello },
  props: {
    id_impianto: {
      type: Number,
      required: false,
    },
    denominazione_impianto: {
      type: String,
    },
  },
  setup(props) {
    const store = useStore();

    const instance = getCurrentInstance();
    const globalApi =
      instance.appContext.config.globalProperties.$apiListGlobal;

    const rowsToSkip = computed(() => store.getters.rowsToSkipOmologList);
    const fetchRows = computed(() => store.getters.fetchRowsOmologList);
    const sortColumn = computed(() => store.getters.sortColumnOmologList);
    const sortOrder = computed(() => store.getters.sortOrderOmologList);

    const getOmologList = () => {
      store.dispatch("setStoreListData", {
        keys: {
          idimpianto: props.id_impianto,
          rowstoskip: rowsToSkip.value,
          fetchrows: fetchRows.value,
          sortcolumn: sortColumn.value,
          sortorder: sortOrder.value,
        },
        apiLink: globalApi.OMOLOGAZIONI_LIST,
        itemName: "omologazioni_list",
      });
    };

    const stagione = computed(() => store.getters.stagioneSelected);
    watch(stagione, () => {
      getOmologList();
    });

    const setFetchRowsOmologList = (e) => {
      store.commit("setFetchRowsOmologList", e);
      getOmologList();
    };
    const setCurrentPageOmologList = (page) => {
      store.commit("setCurrentPageOmologList", page);
      getOmologList();
    };
    const setSortOrderColumn = ({ columnName, order }) => {
      store.commit("setSortColumnOmologList", columnName);
      store.commit("setSortOrderOmologList", order);
      getOmologList();
    };

    const resetFilters = () => {
      store.commit("resetFiltersOmologList");
      getOmologList();
    };
    getOmologList();

    const isLoading = ref();

    const prendiInCarico = (id) => {
      isLoading.value = true;
      inCaricoSiNoOmologazione(id, true).then((res) => {
        if (res.status == 200) {
          alertSuccess("Omologazione presa in carico.");
          getOmologList();
        } else {
          alertFailed(
            res.data.message
              ? res.data.message
              : "Attenzione! Si è verificato un errore. Riprovare più tardi"
          );
        }
        isLoading.value = false;
      });
    };

    const annullaPresaInCarico = (id) => {
      isLoading.value = true;
      inCaricoSiNoOmologazione(id, false).then((res) => {
        if (res.status == 200) {
          alertSuccess("Presa in carico annullata.");
          getOmologList();
        } else {
          alertFailed(
            res.data.message
              ? res.data.message
              : "Attenzione! Si è verificato un errore. Riprovare più tardi"
          );
        }
        isLoading.value = false;
      });
    };

    const inAttesaDiVerifica = (id) => {
      Swal.fire({
        title: "Sei Sicuro?",
        icon: "warning",
        buttonsStyling: false,
        showCancelButton: true,
        cancelButtonText: "Annulla",
        confirmButtonText: "Conferma",
        allowOutsideClick: false,
        customClass: {
          confirmButton: "btn fw-bold btn-light-danger",
          cancelButton: "btn fw-bold btn-light-warning",
        },
      }).then(async (e) => {
        if (e.isDismissed) {
          return;
        }
        if (e.isConfirmed) {
          isLoading.value = true;
          await attesaVerificaOmologazione(id).then((res) => {
            if (res.status == 200) {
              alertSuccess("Operazione effettuata correttamente.");
              getOmologList();
            } else {
              alertFailed(
                res.data.message
                  ? res.data.message
                  : "Attenzione! Si è verificato un errore. Riprovare più tardi"
              );
            }
            isLoading.value = false;
          });
        }
      });
    };

    const approva = (id, id_Impianto) => {
      Swal.fire({
        title: "Sei Sicuro?",
        text: "L'omologazione verrà approvata.",
        icon: "warning",
        buttonsStyling: false,
        showCancelButton: true,
        cancelButtonText: "Annulla",
        confirmButtonText: "Approva",
        allowOutsideClick: false,
        customClass: {
          confirmButton: "btn fw-bold btn-light-success",
          cancelButton: "btn fw-bold btn-light-warning",
        },
      }).then(async (e) => {
        if (e.isDismissed) {
          return;
        }
        if (e.isConfirmed) {
          isLoading.value = true;
          await approvaOmologazione(id, id_Impianto).then((res) => {
            if (res.status == 200) {
              alertSuccess("Omologazione approvata correttamente.");
              getOmologList();
            } else {
              alertFailed(
                res.data.message
                  ? res.data.message
                  : "Attenzione! Si è verificato un errore. Riprovare più tardi"
              );
            }
            isLoading.value = false;
          });
        }
      });
    };

    const rifiuta = (id, id_Impianto) => {
      Swal.fire({
        title: "Sei Sicuro?",
        text: "L'omologazione verrà rifiutata.",
        icon: "warning",
        buttonsStyling: false,
        showCancelButton: true,
        cancelButtonText: "Annulla",
        confirmButtonText: "Rifiuta",
        allowOutsideClick: false,
        customClass: {
          confirmButton: "btn fw-bold btn-light-danger",
          cancelButton: "btn fw-bold btn-light-warning",
        },
      }).then(async (e) => {
        if (e.isDismissed) {
          return;
        }
        if (e.isConfirmed) {
          isLoading.value = true;
          await rifiutaOmologazione(id, id_Impianto).then((res) => {
            if (res.status == 200) {
              alertSuccess("Omologazione rifiutata correttamente.");
              getOmologList();
            } else {
              alertFailed(
                res.data.message
                  ? res.data.message
                  : "Attenzione! Si è verificato un errore. Riprovare più tardi"
              );
            }
            isLoading.value = false;
          });
        }
      });
    };

    const getFormatDate = (date) => {
      const year = date.substring(0, 4);
      const month = date.substring(5, 7);
      const day = date.substring(8, date.length);

      return `${day}/${month}/${year}`;
    };

    const tableHeader = ref([
      {
        name: "Denominazione richiedente",
        key: "richiedente",
      },
      {
        name: "Stagione",
        key: "anno",
      },
      {
        name: "Disciplina",
        key: "disciplina",
        sortable: false,
      },
      {
        name: "Data inizio",
        key: "data_inizio",
        sortable: false,
      },
      {
        name: "Data fine",
        key: "data_fine",
        sortable: false,
      },
      {
        name: "Stato",
        key: "stato",
        sortable: false,
      },
      {
        key: "options",
        sortable: false,
      },
    ]);

    const id_Omologazione = ref(null);
    const stato_omologazione = ref(null);
    const livello = ref(null);
    const refresh = ref(false);

    return {
      omologazioni_list: computed(() =>
        store.getters.getStateFromName("resultsomologazioni_list")
      ),
      loaded: computed(() =>
        store.getters.getStateFromName("loadedomologazioni_list")
      ),
      record: computed(() =>
        store.getters.getStateFromName("recordomologazioni_list")
      ),
      status: computed(() =>
        store.getters.getStateFromName("statusomologazioni_list")
      ),

      currentPage: computed(() => store.getters.currentPageOmologList),
      rowsToSkip: computed(() => store.getters.rowsToSkipOmologList),
      fetchRows: computed(() => store.getters.fetchRowsOmologList),
      sortColumn: computed(() => store.getters.sortColumnOmologList),
      sortOrder: computed(() => store.getters.sortOrderOmologList),
      setFetchRowsOmologList,
      setCurrentPageOmologList,
      setSortOrderColumn,
      getOmologList,
      resetFilters,
      getFormatDate,
      tableHeader,
      id_Omologazione,
      stato_omologazione,
      isLoading,
      prendiInCarico,
      annullaPresaInCarico,
      inAttesaDiVerifica,
      approva,
      rifiuta,
      isEnabled,
      livello,
      refresh,
    };
  },
});
</script>
